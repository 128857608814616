import * as React from "react";

const Authorized = (props) => {
  return (
    <main>
      <title>Not found</title>
      <pre>
        {JSON.stringify(props, null, 2)}
      </pre>
    </main>
  )
}

export default Authorized;
